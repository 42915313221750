import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Testimonials from './components/Testimonials';
import Contact from './pages/Contact';
import { homeData } from './data/homeData';
import { aboutContent } from './data/aboutData';
import { servicesData } from './data/servicesData';
import { contactData } from './data/contactData';
import PageTemplate from './components/PageTemplate';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <PageTemplate {...homeData.helmet}>
              <Home />
            </PageTemplate>
          }
        />
        <Route
          path="/about-us"
          element={
            <PageTemplate {...aboutContent.helmet}>
              <About />
            </PageTemplate>
          }
        />
        <Route
          path="/services"
          element={
            <PageTemplate {...servicesData.helmet}>
              <Services />
            </PageTemplate>
          }
        />
        <Route
          path="/testimonials"
          element={
            <PageTemplate>
              <Testimonials />
            </PageTemplate>
          }
        />
        <Route
          path="/contact"
          element={
            <PageTemplate {...contactData.helmet}>
              <Contact />
            </PageTemplate>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
