import React from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { servicesData } from '../data/servicesData';

const Services = () => {
  const { features, detailedServices } = servicesData;

  return (
    <div id="services" className="bg-white py-24 sm:py-32">
      <Helmet>
        <title>{servicesData.helmet.title}</title>
        <meta name="description" content={servicesData.helmet.description} />
        <meta name="keywords" content={servicesData.helmet.keywords} />
        <meta property="og:title" content={servicesData.helmet.ogTitle} />
        <meta property="og:description" content={servicesData.helmet.ogDescription} />
        <meta property="og:image" content={servicesData.helmet.ogImage} />
        <meta property="og:url" content={servicesData.helmet.ogUrl} />
        <meta name="twitter:card" content={servicesData.helmet.twitterCard} />
        <meta name="twitter:title" content={servicesData.helmet.twitterTitle} />
        <meta name="twitter:description" content={servicesData.helmet.twitterDescription} />
        <meta name="twitter:image" content={servicesData.helmet.twitterImage} />
      </Helmet>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-6xl lg:text-center">
          <motion.h2
            className="text-base font-semibold leading-7 text-cygnet-dark"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Our Services
          </motion.h2>
          <motion.p
            className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            Comprehensive Solutions for Your Business
          </motion.p>
          <motion.p
            className="mt-6 text-lg leading-8 text-gray-600"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            Our core services include call handling, reception services, monitoring emails and outbound Duty of Care calls. Don't let your business fall behind. Give us a call today on 0208 538 5400 and see how Cygnet Call Centre can help your business thrive.
          </motion.p>
        </div>
        {/* <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature, index) => (
              <motion.div
                key={feature.name}
                className="relative pl-16"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.6 + index * 0.1 }}
              >
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-cygnet-dark">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
              </motion.div>
            ))}
          </dl>
        </div> */}
        <div className="mt-16">
          {Object.keys(detailedServices).map((key, index) => (
            <motion.div
              key={key}
              className={`mt-10 flex flex-col lg:flex-row lg:items-center lg:gap-8 ${index % 2 === 0 ? 'lg:flex-row-reverse' : ''}`}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.8 + index * 0.1 }}
            >
              <motion.div
                className="relative w-full lg:w-1/2 mt-10 lg:mt-0"
                initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.8 + index * 0.1 }}
              >
                <div className="h-full flex items-center mt-10">
                  <img
                    alt=""
                    src={detailedServices[key].image}
                    className="relative mx-auto w-full h-full object-fill rounded-xl shadow-lg"
                  />
                </div>
              </motion.div>
              <motion.div
                className="w-full lg:w-1/2 mt-10"
                initial={{ opacity: 0, x: index % 2 === 0 ? 50 : -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.8 + index * 0.1 }}
              >
                <h3 className="text-2xl font-bold tracking-tight text-gray-900">{detailedServices[key].title}</h3>
                <p className="mt-4 text-md leading-6 text-gray-600">{detailedServices[key].description}</p>
                <dl className="mt-10 space-y-10">
                  {detailedServices[key].services.map((service) => (
                    <div key={service.id} className="relative">
                      <dt>
                        <div className="absolute flex h-12 w-12 items-center justify-center rounded-xl bg-cygnet-dark text-white">
                          <service.icon aria-hidden="true" className="h-8 w-8" />
                        </div>
                        <p className="ml-16 text-lg font-medium leading-6 text-gray-900">{service.name}</p>
                      </dt>
                      <dd className="ml-16 mt-2 text-base text-gray-500">{service.description}</dd>
                    </div>
                  ))}
                </dl>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;