import React from 'react';
import logo from '../../assets/logo.jpg'; // Adjust the path as needed

const Logo = () => {
  return (
    <img src={logo} alt="Cygnet Call Centre" className="h-8" />
  );
};

export default Logo;
