export const homeData = {
  title: "Ready to improve your customer support?",
  subtitle: "Get in touch with us today.",
  description: "Contact us to find out how Cygnet Call Centre can help your business thrive with our reliable, efficient, and cost-effective call centre services.",
  primaryButton: {
    text: "Get in Touch",
    href: "#contact",
  },
  secondaryButton: {
    text: "Learn more",
    href: "#services",
  },
  helmet: {
    title: "Cygnet Call Centre - Reliable Call Centre Solutions for Your Business",
    description: "Discover Cygnet Call Centre's efficient and cost-effective call handling services. Enhance your customer support and drive business growth with our expert team. Contact us today!",
    keywords: "Cygnet Call Centre, call centre services, customer support, business services",
    ogTitle: "Cygnet Call Centre - Reliable Call Centre Solutions for Your Business",
    ogDescription: "Discover Cygnet Call Centre's efficient and cost-effective call handling services. Enhance your customer support and drive business growth with our expert team. Contact us today!",
    ogType: "website",
    ogUrl: "https://www.cygnetcallcentre.com/",
    ogImage: "https://www.cygnetcallcentre.com/logo.png",
    twitterCard: "summary_large_image",
    twitterTitle: "Cygnet Call Centre - Reliable Call Centre Solutions for Your Business",
    twitterDescription: "Discover Cygnet Call Centre's efficient and cost-effective call handling services. Enhance your customer support and drive business growth with our expert team. Contact us today!",
    twitterImage: "https://www.cygnetcallcentre.com/logo.png",
  },

  industriesWeServe: [
    {
      image: 'https://images.pexels.com/photos/4386467/pexels-photo-4386467.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: 'Healthcare',
    },
    {
      image: 'https://images.pexels.com/photos/3943947/pexels-photo-3943947.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: 'IT',
    },
    {
      image: 'https://images.pexels.com/photos/4482900/pexels-photo-4482900.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: 'Finance',
    },
    {
      image: 'https://images.pexels.com/photos/4199524/pexels-photo-4199524.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: 'Retail',
    },
    {
      image: 'https://images.pexels.com/photos/2760343/pexels-photo-2760343.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: 'Manufacturing',
    },
    {
      image: 'https://images.pexels.com/photos/8867415/pexels-photo-8867415.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: 'Telecommunications',
    },
    {
      image: 'https://images.pexels.com/photos/4483772/pexels-photo-4483772.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: 'Logistics',
    },
    {
      image: 'https://images.pexels.com/photos/7820376/pexels-photo-7820376.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      title: 'Hospitality',
    },
  ],
};

export const heroData = {
  title: "Ready to improve your customer support?",
  subtitle: "Get in touch with us today.",
  description: "Contact us to find out how Cygnet Call Centre can help your business thrive with our reliable, efficient, and cost-effective call centre services.",
  primaryButton: {
    text: "Get in Touch",
    href: "#contact",
  },
  secondaryButton: {
    text: "Learn more",
    href: "#services",
  },
};