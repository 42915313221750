import React from 'react';
import { motion, useInView } from 'framer-motion';

const partners = [
  { name: 'Kohler', industry: 'Manufacturing' },
  { name: 'Logicalis', industry: 'IT Services' },
  { name: 'Perenco', industry: 'Oil & Gas' },
  { name: 'Blount Shutters', industry: 'Construction' },
  { name: 'Enea', industry: 'Telecommunications' },
  { name: 'Servicetec', industry: 'IT Support' },
  // { name: 'Harbour Solutions', industry: 'Maritime Services' },
];

const LogoCloud = () => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
      delayChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3, 
      ease: "easeOut",
    },
  },
};

  return (
    <motion.section
      className="bg-cygnet-dark py-24 sm:py-32"
      ref={ref}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={containerVariants}
    >
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <motion.div
          className="text-center mb-16"
          variants={containerVariants}
        >
          <motion.h2
            className="text-3xl font-bold tracking-tight text-white sm:text-4xl"
            variants={itemVariants}
          >
            Trusted by Industry Leaders
          </motion.h2>
          <motion.p
            className="mt-4 text-lg leading-8 text-gray-300 max-w-2xl mx-auto"
            variants={itemVariants}
          >
            We've built enduring partnerships with established organizations across various sectors, delivering first-class service tailored to their unique needs.
          </motion.p>
        </motion.div>

        <motion.div
          className="mx-auto grid max-w-lg gap-8 sm:max-w-xl sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
          variants={containerVariants}
        >
          {partners.map((partner, index) => (
            <motion.div
              key={index}
              className="flex flex-col justify-between bg-white/10 backdrop-blur-md rounded-2xl p-8 ring-1 ring-white/10 transition-all duration-300 hover:bg-white/20 hover:scale-105"
              variants={itemVariants}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <div>
                <h3 className="text-xl font-semibold text-white mb-2">{partner.name}</h3>
                <p className="text-sm text-gray-300">{partner.industry}</p>
              </div>
              {/* <div className="mt-4 text-sm text-gray-400">Trusted partner since 2015</div> */}
            </motion.div>
          ))}
        </motion.div>

        <motion.div
          className="mt-16 text-center"
          variants={itemVariants}
        >
          <a href="#contact" className="inline-block px-6 py-3 border border-transparent text-base font-medium rounded-md text-cygnet-dark bg-white hover:bg-gray-100 transition duration-300">
            Join Our Partner Network
          </a>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default LogoCloud;