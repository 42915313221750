import React from 'react'
import { Helmet } from 'react-helmet';
import AboutHero from '../components/AboutHero'
import CoreValues from '../components/CoreValues'
import Mission from '../components/Mission'
import Vision from '../components/Vision'
import FAQ from '../components/FAQ'
import { aboutContent } from '../data/aboutData'

const AboutPage = () => {
  return (
    <>
    <Helmet>
        <title>{aboutContent.helmet.title}</title>
        <meta name="description" content={aboutContent.helmet.description} />
        <meta name="keywords" content={aboutContent.helmet.keywords} />
        <meta property="og:title" content={aboutContent.helmet.ogTitle} />
        <meta property="og:description" content={aboutContent.helmet.ogDescription} />
        <meta property="og:type" content={aboutContent.helmet.ogType} />
        <meta property="og:url" content={aboutContent.helmet.ogUrl} />
        <meta property="og:image" content={aboutContent.helmet.ogImage} />
        <meta name="twitter:card" content={aboutContent.helmet.twitterCard} />
        <meta name="twitter:title" content={aboutContent.helmet.twitterTitle} />
        <meta name="twitter:description" content={aboutContent.helmet.twitterDescription} />
        <meta name="twitter:image" content={aboutContent.helmet.twitterImage} />
      </Helmet>
      <AboutHero />
      {/* <Vision vision={aboutContent.vision} /> */}
      <CoreValues />
      {/* Image section */}
      <div className="mt-16 sm:mt-20 xl:mx-auto xl:max-w-7xl xl:px-8">
        <img
          src="https://images.unsplash.com/photo-1529156069898-49953e39b3ac?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=2832&amp;q=80"
          alt=""
          className="aspect-[5/2] w-full object-cover xl:rounded-3xl"
        />
      </div>
      <Mission mission={aboutContent.mission} />
      <FAQ />
    </>
  )
}

export default AboutPage
