export const aboutContent = {
  subtitle: "Learn more about our journey and values",
  title: 'About Us',
  description: 'At Cygnet Call Centre, we are dedicated to providing exceptional call centre services. Our team of professionals is committed to delivering personalized and efficient customer service to help your business thrive. With our expertise and state-of-the-art technology, we offer customized solutions tailored to your unique needs.',
  mission: "Our mission is to empower businesses of all sizes by providing reliable, efficient, and cost-effective call centre services, enabling them to connect with their customers and drive growth.",
  vision: "Our vision is to be a trusted partner for our clients, supporting them in achieving their business objectives and building long-lasting relationships.",
  team: [
    {
      name: "John Doe",
      position: "CEO",
      imageUrl: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      bio: "John has over 20 years of experience in the call centre industry and is passionate about delivering exceptional customer service."
    },
    {
      name: "Jane Smith",
      position: "CTO",
      imageUrl: "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      bio: "Jane is a technology enthusiast with a background in software development and IT management."
    },
    {
      name: "Alice Johnson",
      position: "COO",
      imageUrl: "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      bio: "Alice has a wealth of experience in operations management and is dedicated to ensuring smooth and efficient processes."
    }
  ],
  helmet: {
    title: "About Us - Cygnet Call Centre's Commitment to Excellence",
    description: "Learn more about Cygnet Call Centre, our mission, vision, and the services we offer.",
    keywords: "About Cygnet Call Centre, our mission, our vision, call centre services",
    ogTitle: "About Us - Cygnet Call Centre's Commitment to Excellence",
    ogDescription: "Learn more about Cygnet Call Centre, our mission, vision, and the services we offer.",
    ogType: "website",
    ogUrl: "https://www.cygnetcallcentre.com/about-us",
    ogImage: "https://www.cygnetcallcentre.com/logo.png",
    twitterCard: "summary_large_image",
    twitterTitle: "About Us - Cygnet Call Centre's Commitment to Excellence",
    twitterDescription: "Learn more about Cygnet Call Centre, our mission, vision, and the services we offer.",
    twitterImage: "https://www.cygnetcallcentre.com/logo.png"
  }
};
