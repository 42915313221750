import {
  PhoneIcon,
  ArrowsRightLeftIcon,
  InboxIcon,
  CalendarIcon,
  ChatBubbleLeftRightIcon,
  ClockIcon,
  ComputerDesktopIcon,
  DocumentTextIcon,
  ChartBarIcon,
  CurrencyDollarIcon,
  ExclamationCircleIcon,
  BellIcon,
  PhoneXMarkIcon,
  ShieldCheckIcon,
  ShoppingCartIcon,
  ClipboardDocumentListIcon,
  HandThumbUpIcon,
} from '@heroicons/react/24/outline';

export const servicesData = {
  helmet: {
    title: "Our Services - Comprehensive Call Centre Solutions",
    description: "Explore the range of services offered by Cygnet Call Centre, including call handling, reception services, and more. Find out how we can support your business with tailored solutions.",
    keywords: "Cygnet Call Centre services, reception services, first line support, duty of care calls, adhoc services",
    ogTitle: "Our Services - Comprehensive Call Centre Solutions",
    ogDescription: "Explore the range of services offered by Cygnet Call Centre, including call handling, reception services, and more. Find out how we can support your business with tailored solutions.",
    ogType: "website",
    ogUrl: "https://www.cygnetcallcentre.com/services",
    ogImage: "https://www.cygnetcallcentre.com/logo.png",
    twitterCard: "summary_large_image",
    twitterTitle: "Our Services - Comprehensive Call Centre Solutions",
    twitterDescription: "Explore the range of services offered by Cygnet Call Centre, including call handling, reception services, and more. Find out how we can support your business with tailored solutions.",
    twitterImage: "https://www.cygnetcallcentre.com/logo.png"
  },
  // features: [
  //   {
  //     name: 'Reception Services',
  //     description: 'Our receptionists provide professional call handling and personalized greetings to ensure your callers have a positive experience.',
  //     icon: CloudArrowUpIcon,
  //   },
  //   {
  //     name: 'First Line Support',
  //     description: 'We offer 24/7 fault logging and real-time email monitoring to ensure your technical issues are documented and resolved promptly.',
  //     icon: LockClosedIcon,
  //   },
  //   {
  //     name: 'Duty of Care Calls',
  //     description: 'We provide regular check-in calls and emergency response services to ensure the safety and well-being of individuals who require assistance.',
  //     icon: ArrowPathIcon,
  //   },
  //   {
  //     name: 'Adhoc Services',
  //     description: 'Our flexible call handling services include overflow support, emergency cover, sales inquiries, and after-sales feedback collection.',
  //     icon: FingerPrintIcon,
  //   },
  // ],
  detailedServices: {
    reception: {
      title: "Reception Services",
      description: "At Cygnet Call Centre, we understand that first impressions matter. That's why we offer professional reception services designed to ensure that every caller receives a warm and welcoming experience. With our dedicated team of receptionists and commitment to excellence, we're here to help you make a positive impression on your clients and customers, every time they call.",
      image: "https://images.pexels.com/photos/8937607/pexels-photo-8937607.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      services: [
        {
          id: 1,
          name: "Live Call Answering",
          description: "Our receptionists are available to answer calls on behalf of your business during business hours, ensuring that every caller receives prompt and professional assistance.",
          icon: PhoneIcon,
        },
        {
          id: 2,
          name: "Call Screening and Forwarding",
          description: "We'll screen incoming calls based on your specific instructions and forward them to the appropriate person or department within your organization, ensuring that important calls are always directed to the right place.",
          icon: ArrowsRightLeftIcon,
        },
        {
          id: 3,
          name: "Message Taking",
          description: "If a caller is unavailable, we can take messages on your behalf and deliver them to you via email, text message, or your preferred method of communication, ensuring that you never miss an important message.",
          icon: InboxIcon,
        },
        {
          id: 4,
          name: "Appointment Scheduling",
          description: "Our receptionists can also assist with scheduling appointments, meetings, and consultations, helping you manage your calendar more efficiently and ensuring that your schedule stays organized.",
          icon: CalendarIcon,
        },
        {
          id: 5,
          name: "Customized Greeting",
          description: "We understand that every business is unique, which is why we offer customized greeting scripts tailored to reflect your brand's personality and values, ensuring that every caller receives a personalized and professional experience.",
          icon: ChatBubbleLeftRightIcon,
        },
      ],
    },
    firstLineSupport: {
      title: "First Line Support",
      description: "At Cygnet Call Centre, we understand that effective fault logging is essential for maintaining the integrity and reliability of your systems and operations. Our dedicated team of skilled technicians is here to provide you with a seamless and efficient fault logging process, ensuring that all reported issues are documented, tracked, and resolved in a timely manner.",
      image: "https://images.pexels.com/photos/7709235/pexels-photo-7709235.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      services: [
        {
          id: 1,
          name: "24/7 Fault Logging",
          description: "Our helpdesk is available 24 hours a day, 7 days a week to receive and log fault reports from your users, ensuring that no issue goes unaddressed.",
          icon: ClockIcon,
        },
        {
          id: 2,
          name: "Real-Time Email Monitoring",
          description: "We offer 24/7 real-time monitoring of your emails to log tickets and respond any urgent queries.",
          icon: ComputerDesktopIcon,
        },
        {
          id: 3,
          name: "Detailed Documentation",
          description: "Our helpdesk agents meticulously document all reported faults either on client platforms or our in house call logging software including relevant details such as the nature of the issue, the affected systems or components, and allocating jobs to the relevant engineers.",
          icon: DocumentTextIcon,
        },
        {
          id: 4,
          name: "Customized Reporting",
          description: "We understand that every business is unique, which is why we offer customized reporting solutions tailored to your specific needs and objectives. Whether you require daily updates on critical metrics or in-depth monthly analysis, we can create reporting packages that align with your requirements.",
          icon: ChartBarIcon,
        },
        {
          id: 5,
          name: "Cost-Effective Solutions",
          description: "Outsourcing your first-line support to us can help you save on overhead costs associated with hiring and training in-house support staff, while still ensuring high-quality service and support for your users.",
          icon: CurrencyDollarIcon,
        },
      ],
    },
    dutyOfCare: {
      title: "Duty of Care Calls",
      description: "We understand the importance of maintaining a duty of care through regular check-in calls. Our duty of care calls service is designed to provide peace of mind and support to individuals who may require assistance, ensuring their safety, well-being, and comfort.",
      image: "https://images.pexels.com/photos/4021808/pexels-photo-4021808.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      services: [
        {
          id: 1,
          name: "Regular Check-In Calls",
          description: "We offer scheduled check-in calls to individuals who may benefit from additional support or monitoring. Our compassionate and trained staff members make regular calls to check on the well-being of participants, providing reassurance and assistance as needed.",
          icon: PhoneIcon,
        },
        {
          id: 2,
          name: "Emergency Response",
          description: "In the event of an emergency or urgent situation, our duty of care call service is equipped to respond quickly and effectively. Our staff members are trained to assess the situation, provide appropriate support and guidance, and coordinate emergency services if necessary.",
          icon: ExclamationCircleIcon,
        },
        {
          id: 3,
          name: "Medication Reminders",
          description: "For individuals who require assistance with medication management, we provide medication reminder calls to ensure that medications are taken as prescribed. Our calls help individuals stay on track with their medication regimen and reduce the risk of missed doses.",
          icon: BellIcon,
        },
      ],
    },
    adhoc: {
      title: "Adhoc Services",
      description: "We can provide a back up/overflow call centre service to enable you and your team to attend meetings, office events and parties. Our service can be used to provide call cover during emergencies, technical issues, and call overflow. Our call centre service is available 24/7, 365 days a year, so you can be sure that your business is fully prepared to handle any emergency call handling situations that may arise. Our telephone answering service can provide round-the-clock support for your customers, as well as a 24-hour helpdesk service. This can be especially beneficial for businesses that need to provide customer service support on a full-time basis.",
      image: "https://images.pexels.com/photos/7564287/pexels-photo-7564287.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      services: [
        {
          id: 1,
          name: "Overflow Call Centre",
          description: "Our flexible call handling services include overflow support, emergency cover, sales inquiries, and after-sales feedback collection.",
          icon: PhoneXMarkIcon,
        },
        {
          id: 2,
          name: "Emergency Cover",
          description: "Our flexible call handling services include overflow support, emergency cover, sales inquiries, and after-sales feedback collection.",
          icon: ShieldCheckIcon,
        },
        {
          id: 3,
          name: "Sales Enquiries",
          description: "Our flexible call handling services include overflow support, emergency cover, sales inquiries, and after-sales feedback collection.",
          icon: ShoppingCartIcon,
        },
        {
          id: 4,
          name: "Market/ Satisfaction Surveys",
          description: "Our flexible call handling services include overflow support, emergency cover, sales inquiries, and after-sales feedback collection.",
          icon: ClipboardDocumentListIcon,
        },
        {
          id: 5,
          name: "After Sales Feedback Collection",
          description: "Our flexible call handling services include overflow support, emergency cover, sales inquiries, and after-sales feedback collection.",
          icon: HandThumbUpIcon,
        },
      ],
    },
  },
};