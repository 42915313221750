// src/components/DesktopNavMenu.jsx
import React from 'react';
import { navigation } from '../../data/navigation';
import { Link } from 'react-router-dom';

const DesktopNavMenu = ({ onMenuItemClick }) => {
  return (
    <div className="hidden lg:flex lg:gap-x-12">
      {navigation.map((item) => (
        <Link 
          key={item.name} 
          to={item.href} 
          className="text-sm font-semibold leading-6 text-cygnet-dark"
          onClick={onMenuItemClick} // Call the scroll-to-top function
        >
          {item.name}
        </Link>
      ))}
    </div>
  );
};

export default DesktopNavMenu;
