import React from 'react';
import { motion, useInView } from 'framer-motion';
import { homeData } from '../data/homeData';

const IndustriesWeServe = () => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.section
      className="bg-gray-900 py-20"
      ref={ref}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={containerVariants}
    >
      <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-20 lg:px-8">
        <motion.div
          className="mx-auto max-w-2xl lg:mx-0"
          variants={containerVariants}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
        >
          <motion.h2
            className="text-3xl font-bold tracking-tight text-white sm:text-4xl"
            variants={itemVariants}
          >
            Industries We Serve
          </motion.h2>
          <motion.p
            className="mt-6 text-lg leading-8 text-gray-300"
            variants={itemVariants}
          >
            Cygnet Call Centre has extensive experience serving a wide range of
            industries, delivering tailored solutions to meet their unique needs.
          </motion.p>
        </motion.div>
        <motion.ul
          role="list"
          className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-14 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 xl:grid-cols-4"
          variants={containerVariants}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
        >
          {homeData.industriesWeServe.map((industry, index) => (
            <motion.li
              key={index}
              variants={itemVariants}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <img
                className="aspect-[14/13] w-full rounded-2xl object-cover"
                src={industry.image}
                alt={industry.title}
              />
              <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-white">
                {industry.title}
              </h3>
            </motion.li>
          ))}
        </motion.ul>
      </div>
    </motion.section>
  );
};

export default IndustriesWeServe;