export const testimonials = [
  {
    body: 'Cygnet Call Centre has been an invaluable partner for Kohler, handling our customer support with professionalism and care. Their expertise in our industry has significantly improved our customer satisfaction rates.',
    author: {
      // name: 'Jane Doe',
      // handle: 'Customer Service Director',
      // imageUrl: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      company: 'Kohler'
    },
  },
  {
    body: 'We\'ve been working with Cygnet for over a decade, and they consistently deliver high-quality service. Their ability to adapt to our changing needs in the IT sector has been crucial to our growth.',
    author: {
      // name: 'John Smith',
      // handle: 'Operations Manager',
      // imageUrl: 'https://images.pexels.com/photos/10402659/pexels-photo-10402659.jpeg?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      company: 'Logicalis'
    },
  },
  {
    body: 'Cygnet Call Centre has transformed our customer service in the oil and gas industry. Their professional team consistently delivers exceptional results, even in our complex technical environment.',
    author: {
      // name: 'David Brown',
      // handle: 'CEO',
      // imageUrl: 'https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      company: 'Perenco'
    },
  },
  {
    body: 'The level of care and attention to detail provided by Cygnet is unmatched. They truly feel like an extension of our team, understanding the unique needs of the construction industry.',
    author: {
      // name: 'Emily Davis',
      // handle: 'Customer Relations Manager',
      // imageUrl: 'https://images.unsplash.com/photo-1529333166437-7750a6dd5a70?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      company: 'Blount Shutters'
    },
  },
  {
    body: 'Cygnet has been a trusted partner in providing technical support for our telecommunications customers. Their skilled agents handle complex issues efficiently and effectively.',
    author: {
      // name: 'Michael Johnson',
      // handle: 'Customer Support Manager',
      // imageUrl: 'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      company: 'Enea'
    },
  },
  {
    body: 'Working with Cygnet has allowed us to focus on our core IT services while knowing our customer interactions are in good hands. Their flexibility and responsiveness in the tech industry are unparalleled.',
    author: {
      // name: 'Sarah Lee',
      // handle: 'Operations Manager',
      // imageUrl: 'https://images.pexels.com/photos/8528744/pexels-photo-8528744.jpeg?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      company: 'Servicetec'
    },
  },
]