import React, { useState } from 'react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import Logo from './Logo';
import DesktopNavMenu from './DesktopNavMenu';
import MobileNavMenu from './MobileNavMenu';

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMenuItemClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <header className="sticky top-0 z-10 bg-white shadow">
      <nav className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="/" aria-label="Home">
            <Logo /> {/* Ensure Logo does not wrap another <a> */}
          </a>
        </div>
        <DesktopNavMenu onMenuItemClick={handleMenuItemClick} />
        <div className="flex flex-1 items-center justify-end gap-x-6">
          <a
            href="tel:+442085385400"
            className="rounded-md bg-cygnet-dark px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cygnet-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cygnet-dark"
          >
            Get in Touch
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </nav>
      <MobileNavMenu mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} onMenuItemClick={handleMenuItemClick} />
    </header>
  );
};

export default Navbar;
