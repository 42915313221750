import React from 'react';

const Mission = ({ mission }) => {
  return (
    <div className="bg-white py-12">
        <div className="mx-auto mt-8 max-w-7xl px-6 sm:mt-40 lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Mission</h2>
          <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
            <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
              <p className="text-xl leading-8 text-gray-600">
                Our mission is to empower businesses of all sizes by providing reliable, efficient, and cost-effective call centre services, enabling them to connect with their customers and drive growth.
              </p>
              <div className="mt-10 max-w-xl text-base leading-7 text-gray-700">
                <p>
                  We strive to deliver the highest level of customer service, utilising our expertise and state-of-the-art technology to provide customised solutions that meet the unique needs of our clients. Our goal is to be a trusted partner for our clients, supporting them in achieving their business objectives and building long-lasting relationships.
                </p>
                <p className="mt-10">
                  Our ISO9001 certification ensures that all of our solutions meet the highest international standards, while our ICO certification guarantees the protection of your data.
                </p>
              </div>
            </div>
            <div className="lg:flex lg:flex-auto lg:justify-center">
              <dl className="w-64 space-y-8 xl:w-80">
                <div className="flex flex-col-reverse gap-y-4">
                  <dt className="text-base leading-7 text-gray-600">Years of Experience</dt>
                  <dd className="text-5xl font-semibold tracking-tight text-gray-900">24+</dd>
                </div>
                <div className="flex flex-col-reverse gap-y-4">
                  <dt className="text-base leading-7 text-gray-600">Happy Clients</dt>
                  <dd className="text-5xl font-semibold tracking-tight text-gray-900">1,000+</dd>
                </div>
                <div className="flex flex-col-reverse gap-y-4">
                  <dt className="text-base leading-7 text-gray-600">Calls Managed Annually</dt>
                  <dd className="text-5xl font-semibold tracking-tight text-gray-900">1 Million+</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Mission;
