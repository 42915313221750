export const contactData = {
  title: "Contact Us",
  address: "Unit 5 Hounslow Business Park, Alice Way, Hounslow, TW3 3UD",
  phone: "0208 538 5400",
  email: "contact@cygnetcall.co.uk",
  helmet: {
    title: "Contact Us - Cygnet Call Centre",
    description: "Get in touch with Cygnet Call Centre for reliable, efficient, and cost-effective call centre services. Contact us today to learn more.",
    keywords: "Contact Cygnet Call Centre, call centre services, customer support",
    ogTitle: "Contact Us - Cygnet Call Centre",
    ogDescription: "Get in touch with Cygnet Call Centre for reliable, efficient, and cost-effective call centre services. Contact us today to learn more.",
    ogType: "website",
    ogUrl: "https://www.cygnetcallcentre.com/contact",
    ogImage: "https://www.cygnetcallcentre.com/logo.png",
    twitterCard: "summary_large_image",
    twitterTitle: "Contact Us - Cygnet Call Centre",
    twitterDescription: "Get in touch with Cygnet Call Centre for reliable, efficient, and cost-effective call centre services. Contact us today to learn more.",
    twitterImage: "https://www.cygnetcallcentre.com/logo.png"
  }
};

