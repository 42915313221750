import React from 'react';
import FeatureList from './FeatureList';

const features = [
  {
    name: 'Reception Services',
    description:
      'Professional call answering, message taking, and appointment scheduling to keep your business running smoothly.',
  },
  {
    name: 'First Line Support',
    description:
      'Efficient fault logging, real-time email monitoring, and technical issue resolution for your customers.',
  },
  {
    name: 'Duty of Care Calls',
    description:
      'Regular check-ins, emergency response, and medication reminders for vulnerable individuals.',
  },
  {
    name: 'Adhoc Services',
    description:
      'Flexible solutions including overflow call handling, emergency cover, and customer surveys.',
  },
  {
    name: 'Outbound Sales',
    description:
      'Targeted outbound calling campaigns to generate leads, conduct market research, and drive sales.',
  },
  {
    name: 'Customer Retention',
    description:
      'Proactive customer engagement strategies to improve loyalty, reduce churn, and drive repeat business.',
  },
];

const Features = () => {
  return (
    <FeatureList
      features={features}
      title="What can Cygnet Call Centre do for your business?"
      description="Our Comprehensive Services"
    />
  );
};

export default Features;
