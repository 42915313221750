// src/data/footerData.js
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';

export const footerNavigation = {
  main: [
    { name: 'Home', href: '/' },
    { name: 'About Us', href: '/about-us' },
    { name: 'Services', href: '/services' },
    { name: 'Testimonials', href: '/testimonials' },
    // { name: 'Contact', href: '/contact' },
  ],
  social: [
    { name: 'Facebook', href: 'https://facebook.com', icon: FaFacebook },
    { name: 'Twitter', href: 'https://twitter.com', icon: FaTwitter },
    { name: 'LinkedIn', href: 'https://linkedin.com', icon: FaLinkedin },
  ],
};
