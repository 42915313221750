// src/components/MobileNavMenu.jsx
import React from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import Logo from './Logo';
import { navigation } from '../../data/navigation';

const MobileNavMenu = ({ mobileMenuOpen, setMobileMenuOpen, onMenuItemClick }) => {

  return (
    <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
      <div className="fixed inset-0 z-50" />
      <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        <div className="flex items-center justify-between">
          <Logo />
          <button
            type="button"
            className="-m-2.5 rounded-md p-2.5 text-cygnet-dark"
            onClick={() => setMobileMenuOpen(false)}
          >
            <span className="sr-only">Close menu</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-6 flow-root">
          <div className="-my-6 divide-y divide-gray-500/10">
            <div className="space-y-2 py-6">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-cygnet-dark hover:bg-gray-50"
                  onClick={() => {
                    onMenuItemClick(); // Call the scroll-to-top function
                    setMobileMenuOpen(false); // Close the mobile menu
                  }}
                >
                  {item.name}
                </a>
              ))}
            </div>
            <div className="py-6">
              <a
                href="tel:+442085385400" // Updated to use tel: link
                className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-cygnet-dark hover:bg-gray-50"
                onClick={() => {
                  onMenuItemClick(); // Call the scroll-to-top function
                  setMobileMenuOpen(false); // Close the mobile menu
                }}
              >
                Get in Touch
              </a>
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  );
};

export default MobileNavMenu;
