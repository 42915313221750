import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from './Navbar/Navbar';
import Footer from './Footer';

const PageTemplate = ({ title, description, keywords, ogTitle, ogDescription, ogUrl, ogImage, twitterCard, twitterTitle, twitterDescription, twitterImage, children }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={ogTitle} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:image" content={ogImage} />
        <meta name="twitter:card" content={twitterCard} />
        <meta name="twitter:title" content={twitterTitle} />
        <meta name="twitter:description" content={twitterDescription} />
        <meta name="twitter:image" content={twitterImage} />
      </Helmet>
      <Navbar />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default PageTemplate;
