import { ChatBubbleLeftIcon, PhoneIcon, InboxIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { motion, useInView } from 'framer-motion';

const features = [
  {
    name: "Let's talk.",
    description:
      'Tell us about your business, your customers, and your goals. We work with you to create call flows to give your clients the best experience.',
    icon: ChatBubbleLeftIcon,
  },
  {
    name: 'Let us handle your calls.',
    description:
      'Our friendly call centre agents engage and interact with your leads and customers, 24/7.',
    icon: PhoneIcon,
  },
  {
    name: 'Stay up-to-date Anywhere.',
    description:
      'See details of every customer interaction via our app, SMS, or email.',
    icon: InboxIcon,
  },
];

const BusinessVoiceSection = () => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
      delayChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3, 
      ease: "easeOut",
    },
  },
};

  return (
    <div className="bg-gray-50 py-24 sm:py-32" ref={ref}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <motion.div
          className="mx-auto max-w-2xl lg:text-center"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
        >
          <motion.h2
            className="text-base font-semibold leading-7 text-cygnet-dark"
            variants={itemVariants}
          >
            The voice (and ears) of your business.
          </motion.h2>
          <motion.p
            className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
            variants={itemVariants}
          >
            Our friendly virtual call centre agents are an extension of your business.
          </motion.p>
          <motion.p
            className="mt-6 text-lg leading-8 text-gray-600"
            variants={itemVariants}
          >
            From call answering to lead qualification, our agents combine expertise, efficiency, and empathy to provide a consistently professional service.
          </motion.p>
        </motion.div>
        <motion.div
          className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
        >
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature, index) => (
              <motion.div
                key={feature.name}
                className="flex flex-col"
                variants={itemVariants}
              >
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                  <feature.icon aria-hidden="true" className="h-5 w-5 flex-none text-cygnet-dark" />
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                </dd>
              </motion.div>
            ))}
          </dl>
        </motion.div>
      </div>
    </div>
  );
};

export default BusinessVoiceSection;