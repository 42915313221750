import React from 'react';
import { contactData } from '../data/contactData';
import Contact from '../components/Contact';

const ContactInfo = () => {
  const title = "Contact Us";
  const description = "We would love to hear from you. Please fill out the form below to get in touch with us.";

  return (
    <div className="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {/* <div className="mx-auto max-w-xl text-center">
          <h2 className="text-lg font-semibold leading-8 tracking-tight text-cygnet-dark">Contact Us</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Get in touch with us today
          </p>
        </div> */}
        <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
          <div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
            {/* Contact information and form goes here */}
          </div>
        </div>
      </div>
      <Contact title={title} description={description} />
    </div>
  );
};

export default ContactInfo;
