import React from 'react';
import { Helmet } from 'react-helmet';
import { homeData } from '../data/homeData'; // Import the homeData
import AboutHero from '../components/AboutHero';
import LogoCloud from '../components/LogoCloud';
import Features from '../components/Features';
import Testimonials from '../components/Testimonials';
import IndustriesWeServe from '../components/IndustriesWeServe';
import BusinessVoiceSection from '../components/BusinessVoiceSection';
// import Contact from '../components/Contact';

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>{homeData.helmet.title}</title>
        <meta name="description" content={homeData.helmet.description} />
        <meta name="keywords" content={homeData.helmet.keywords} />
        <meta property="og:title" content={homeData.helmet.ogTitle} />
        <meta property="og:description" content={homeData.helmet.ogDescription} />
        <meta property="og:image" content={homeData.helmet.ogImage} />
        <meta property="og:url" content={homeData.helmet.ogUrl} />
        <meta name="twitter:card" content={homeData.helmet.twitterCard} />
        <meta name="twitter:title" content={homeData.helmet.twitterTitle} />
        <meta name="twitter:description" content={homeData.helmet.twitterDescription} />
        <meta name="twitter:image" content={homeData.helmet.twitterImage} />
      </Helmet>
      <AboutHero />
      <LogoCloud />
      <Features />
      <IndustriesWeServe />
      <Testimonials />
      <BusinessVoiceSection />
      {/* <Contact title={title} description={description} /> */}
    </div>
  );
};

export default Home;