import React from 'react';

const values = [
  { title: 'Customer Centricity', description: 'Putting the customer at the heart of everything we do. This means prioritizing their needs, providing exceptional service, and striving to exceed their expectations.' },
  { title: 'Professionalism', description: 'Maintaining a high standard of professionalism in all interactions with customers, colleagues, and stakeholders. This includes being courteous, respectful, and knowledgeable at all times.' },
  { title: 'Teamwork', description: 'Collaborating effectively with colleagues to achieve common goals and deliver outstanding results. This involves supporting each other, sharing knowledge and resources, and fostering a positive work environment.' },
  { title: 'Integrity', description: 'Acting with honesty, transparency, and integrity in all aspects of our work. This includes being trustworthy, ethical, and accountable for our actions.' },
  { title: 'Innovation', description: 'Continuously seeking new and improved ways to serve our customers and enhance our services. Embracing change and innovation to stay ahead in the industry.' },
  { title: 'Empathy', description: 'Understanding and addressing the needs and concerns of our customers with compassion and care. Building strong relationships based on trust and empathy.' },
];

const CoreValues = () => {
  return (
    <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
      <div className="mx-auto max-w-2xl lg:mx-0">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Values</h2>
        <p className="mt-6 text-lg leading-8 text-gray-600">Our values guide our actions and define who we are as a company.</p>
      </div>
      <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        {values.map((value, index) => (
          <div key={index}>
            <dt className="font-semibold text-gray-900">{value.title}</dt>
            <dd className="mt-1 text-gray-600">{value.description}</dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default CoreValues;