import React from 'react';
import { motion } from 'framer-motion';

const Contact = ({ title, description }) => {
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
      delayChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3, 
      ease: "easeOut",
    },
  },
};

  return (
    <div className="bg-cygnet-dark py-20 sm:py-32" id="contact">
      <div className="container mx-auto px-4 sm:px-0 text-center">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
        >
          <motion.h2
            className="text-3xl font-bold mb-4 sm:mb-8 text-white"
            variants={itemVariants}
          >
            {title}
          </motion.h2>
          <motion.p
            className="text-lg mb-6 sm:mb-8 text-gray-300"
            variants={itemVariants}
          >
            {description}
          </motion.p>
        </motion.div>
        <motion.form
          className="max-w-lg mx-auto"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
        >
          <motion.div
            className="mb-4 sm:mb-6"
            variants={itemVariants}
          >
            <input
              type="text"
              placeholder="Your Name"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-white"
            />
          </motion.div>
          <motion.div
            className="mb-4 sm:mb-6"
            variants={itemVariants}
          >
            <input
              type="email"
              placeholder="Your Email"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-white"
            />
          </motion.div>
          <motion.div
            className="mb-4 sm:mb-6"
            variants={itemVariants}
          >
            <textarea
              placeholder="Your Message"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-white"
              rows="4"
            ></textarea>
          </motion.div>
          <motion.button
            type="submit"
            className="bg-white text-cygnet-dark px-4 py-2 rounded-md hover:bg-gray-100 transition-colors duration-300"
            variants={itemVariants}
          >
            Send Message
          </motion.button>
        </motion.form>
      </div>
    </div>
  );
};

export default Contact;