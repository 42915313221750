import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: "What services does Cygnet Call Centre offer?",
    answer:
      "Cygnet Call Centre offers a range of services including live call answering, call screening and forwarding, message taking, appointment scheduling, fault logging, duty of care calls, and more.",
  },
  {
    question: "Are your services available 24/7?",
    answer:
      "Yes, our call centre services are available 24/7, 365 days a year to ensure that your business is always connected with your customers.",
  },
  {
    question: "How do you ensure data security?",
    answer:
      "We are ISO9001 and ICO certified, ensuring that all our processes meet the highest international standards and that your data is protected with the utmost security.",
  },
  {
    question: "Can you customize your services to fit my business needs?",
    answer:
      "Absolutely. We provide customized solutions tailored to meet the specific needs of your business, ensuring that our services align perfectly with your objectives.",
  },
  {
    question: "What industries do you support?",
    answer:
      "We support a wide range of industries including air conditioning, healthcare, IT/computer maintenance, property maintenance, security services, and many more.",
  },
  {
    question: "How can I get started with Cygnet Call Centre?",
    answer:
      "Getting started is easy. Simply contact us through our website or call us at 0208 538 5400 to discuss your requirements and we'll set up a tailored solution for your business.",
  },
]

export default function FAQ() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently Asked Questions</h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure key={faq.question} as="div" className="pt-6">
                <dt>
                  <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                    <span className="text-base font-semibold leading-7">{faq.question}</span>
                    <span className="ml-6 flex h-7 items-center">
                      <PlusSmallIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden" />
                      <MinusSmallIcon aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden" />
                    </span>
                  </DisclosureButton>
                </dt>
                <DisclosurePanel as="dd" className="mt-2 pr-12">
                  <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                </DisclosurePanel>
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
