import React from 'react';
import { footerNavigation } from '../data/footerData';
import Logo from '../components/Navbar/Logo';

export default function Footer() {
  return (
    <footer className="bg-gray-50">
      <div className="container mx-auto px-6 py-20">
        <div className="flex flex-wrap -mx-6">
          <div className="w-full px-6 md:w-1/2 lg:w-1/4">
            <a href="/">
              <Logo />
            </a>
            <p className="mt-6 text-gray-600">
              At Cygnet Call Centre, we deliver dependable, efficient, and affordable call centre solutions that support the growth and success of your business.
            </p>
          </div>
          <div className="w-full px-6 mt-12 md:w-1/2 lg:w-1/4">
            <h6 className="text-lg font-semibold text-gray-900">Socials</h6>
            <ul className="mt-4 flex space-x-4">
              {footerNavigation.social.map((item) => (
                <li key={item.name}>
                  <a href={item.href} className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-6 w-6" aria-hidden="true" />
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full px-6 mt-12 md:w-1/2 lg:w-1/4">
            <h6 className="text-lg font-semibold text-gray-900">Quick Links</h6>
            <ul className="mt-4 space-y-2">
              {footerNavigation.main.map((item) => (
                <li key={item.name}>
                  <a href={item.href} className="text-gray-600 hover:text-gray-900">{item.name}</a>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full px-6 mt-12 md:w-1/2 lg:w-1/4">
            <h6 className="text-lg font-semibold text-gray-900">Location & Contact</h6>
            <p className="text-gray-600 mt-4">Unit 5 Hounslow Business Park, Alice Way, Hounslow, TW3 3UD</p>
            <p className="text-gray-600 mt-2">(020) 8538 5400</p>
          </div>
        </div>
      </div>
      <div className="container mx-auto max-w-7xl border-t border-gray-200 py-6 text-center">
        <p className="text-gray-600">
          &copy; {new Date().getFullYear()} Cygnet Call Centre, Ltd. All rights reserved.
        </p>
      </div>
    </footer>
  );
}
